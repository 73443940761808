


























import Vue from 'vue';
export default Vue.extend({
  'name': 'ComponentNavMenu',
  'props': {
    'navMenus': {
      'required': true
    }
  }
});
