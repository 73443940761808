import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import app from './app';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

interface AppState{
  title: string,
  appName: string
}

const state: AppState = {
  'title': '',
  'appName': ''
};

export default new Vuex.Store({
  state,
  'mutations': {
    setTitle (state: AppState, title: string):void{
      state.title = title;
    }
  },
  'actions': {
  },
  'modules': {
    auth,
    app
  },
  'plugins': [createPersistedState()]
});
