<template>
  <div
    ref="searchableInput"
    class="searchable-dropdown"
    v-click-outside="handleClickOutside"
  >
    <input
      v-show="!isDropdownShown"
      :class="['form-control', inputClass]"
      @click="inputClicked"
      v-model="displayValue"
      :placeholder="placeholder"
      readonly
    />
    <input
      v-show="isDropdownShown"
      ref="inputField"
      @focus="$emit('inputFocus')"
      @input="triggerSearch"
      :class="['form-control', inputClass]"
      :placeholder="searchPlaceholder"
      v-model="inputText"
    />
    <div
      ref="dropdownValues"
      v-show="isDropdownShown"
      class="searchable-dropdown-overlay"
      :style="{
        maxHeight: `${maxHeight}px`,
        minWidth: `${minWidth}px`,
      }"
    >
      <template v-if="isLoading">
        <div class="searchable-dropdown-item">
          <span class="font-italic indicator-text">Loading results...</span>
        </div>
      </template>
      <template v-else>
        <template v-if="inputText.length === 0 && options.length === 0">
          <div
            class="searchable-dropdown-item"
          >
            <span class="font-italic indicator-text"
              >Type something to search...</span
            >
          </div>
        </template>
        <template v-else>
          <div
            v-if="options.length === 0"
            class="searchable-dropdown-item"
          >
            <span class="font-italic indicator-text">No result</span>
          </div>

          <template v-else>
            <a
              href="javascript: void(0);"
              :class="[
                'searchable-dropdown-item',
                { selected: value === option.value },
              ]"
              v-for="(option, index) in options"
              :key="index"
              @click="selectOption(option.value)"
            >
              {{ option.text }}
            </a>
          </template>
        </template>
        <a
          v-if="showCreate"
              href="javascript: void(0);"
              class="searchable-dropdown-item"
              @click="createNew"
            >
              {{ createText }}
        </a>
      </template>
    </div>
  </div>
</template>

<script type="ts">
import ClickOutside from 'vue-click-outside';
import Vue from 'vue';
export default Vue.extend({
  'name': 'SearchableDropdown',
  'directives': {
    ClickOutside
  },
  'props': {
    'options': {
      'type': Array,
      default () {
        return [];
      }
    },
    'placeholder': {
      'type': String,
      'default': 'Type to search'
    },
    'searchPlaceholder': {
      'type': String,
      'default': 'Type to search'
    },
    'isLoading': {
      'type': Boolean,
      'default': false
    },
    'maxHeight': {
      'type': Number,
      'default': 400
    },
    'minWidth': {
      'type': Number,
      'default': 400
    },
    'inputClass': {
      'type': String,
      'default': ''
    },
    'value': {
      'default': null
    },
    'displayValue': {
      'default': ''
    },
    'showCreate': {
      'type': Boolean,
      'default': false
    },
    'createText': {
      'default': 'Create New'
    }
  },
  'watch': {
    isDropdownShown (isShown) {
      this.$nextTick(() => {
        if (isShown) {
          this.$nextTick(() => {
            this.$refs.inputField.select();
          });
        }
        this.$refs.dropdownValues.scrollTop = 0;
      });
    }
  },
  data () {
    return {
      'isDropdownShown': false,
      'inputText': ''
    };
  },
  'methods': {
    handleClickOutside () {
      const searchableInput = this.$refs.searchableInput;
      const searchableInputNodes = [];
      searchableInputNodes.push(searchableInput);

      searchableInput.childNodes.forEach((node) => {
        searchableInputNodes.push(node);
      });

      if (
        !searchableInputNodes.some((dom) => dom === event.target) &&
        this.isDropdownShown
      ) {
        this.dismissDropdown();
      }
    },
    dismissDropdown () {
      this.isDropdownShown = false;
    },
    inputClicked () {
      this.isDropdownShown = !this.isDropdownShown;
    },
    selectOption (selectedValue) {
      this.$emit('selected', selectedValue);
      this.isDropdownShown = false;
    },
    triggerSearch () {
      this.isDropdownShown = true;
      this.$emit('search', this.inputText);
    },
    createNew () {
      this.$emit('createNew', this.inputText);
      this.isDropdownShown = false;
    }
  }
});
</script>

<style>
.searchable-dropdown .searchable-dropdown-overlay {
  margin-top: 3px;
  z-index: 1;
  position: absolute;
  background-color: #fff;
  overflow-y: auto;
  padding: 5px 0;
  border: solid #ccc 1px;
  border-radius: 5px;
}

.searchable-dropdown .searchable-dropdown-overlay .searchable-dropdown-item {
  padding: 5px 10px;
  display: block;
  text-decoration: none;
  color: initial;
}

.searchable-dropdown
  .searchable-dropdown-overlay
  a.searchable-dropdown-item:hover,
.searchable-dropdown
  .searchable-dropdown-overlay
  a.searchable-dropdown-item.selected {
  color: #fff;
  background-color: #0d6efd;
}

.searchable-dropdown
  .searchable-dropdown-overlay
  a.searchable-dropdown-item:active {
  background-color: #0d6efd;
}

.indicator-text {
  color: #9499a8;
}
</style>
