export interface User {
  token: string | null,
  username: string | null,
  userId: number | null,
  role: number | null,
}

interface AuthState {
  user: User
}

const state: AuthState = {
  'user': initUser()
};

function initUser (): User {
  return {
    'username': null,
    'token': null,
    'userId': null,
    'role': null
  };
}

function resetUser (state: AuthState) {
  state.user.username = null;
  state.user.token = null;
  state.user.userId = null;
  state.user.role = null;
}

function setAuthenticationStatus (state: AuthState, user: User): void {
  state.user.username = user.username;
  state.user.token = user.token;
  state.user.userId = user.userId;
  state.user.role = user.role;
}

function resetAuthStatus (state: AuthState): void {
  resetUser(state);
}

const mutations = {
  setAuthenticationStatus,
  resetAuthStatus
};

const getters = {
  isSuperAdmin (state: AuthState): boolean {
    return state.user?.role === 0;
  },
  isAdmin (state: AuthState): boolean {
    return state.user?.role === 1;
  },
  isDealer (state: AuthState): boolean {
    return state.user?.role === 2;
  },
  isCustomer (state: AuthState): boolean {
    return state.user?.role === 3;
  },
  myUserId (state: AuthState): number | null {
    return state.user?.userId;
  }
};

export default {
  state,
  mutations,
  getters
};
