import { NavMenu } from '@/types/NavMenu';

export interface AppState {
    currentViewingDealerId: number,
}

function initAppState (): AppState {
  return {
    'currentViewingDealerId': 0
  };
}

const state: AppState = initAppState();

function updateViewDealerId (state: AppState, viewDealerId: number): void {
  state.currentViewingDealerId = viewDealerId;
}

function resetAppState (state: AppState): void {
  state.currentViewingDealerId = 0;
}

const mutations = {
  updateViewDealerId,
  resetAppState
};

const getters = {
  viewDealerId (state: AppState, getters:any, rootState:any, rootGetters:any):number|null{
    return (rootGetters.isSuperAdmin || rootGetters.isAdmin) ? state.currentViewingDealerId : rootGetters.myUserId;
  },
  navMenus (state: AppState, getters:any, rootState:any): Array<NavMenu> {
    switch (rootState.auth.user.role) {
      case 0:
      case 1:
        return [
          {
            'text': 'Admin List',
            'routeName': 'AdminList',
            'iconSrc': 'admin_list'
          },
          {
            'text': 'Dealer List',
            'routeName': 'AdminDealerList',
            'iconSrc': 'dealer'
          },
          {
            'text': 'Customer List',
            'routeName': 'AdminCustomerList',
            'iconSrc': 'customer'
          },
          {
            'text': 'Dealer Type',
            'routeName': 'AdminDealerTypeList',
            'iconSrc': 'dealer_type'
          }
        ];
      case 2:
        return [
          {
            'text': 'Customer List',
            'routeName': 'DealerCustomerList',
            'iconSrc': 'customer'
          },
          {
            'text': 'Stock Tracking',
            'routeName': 'DealerStockList',
            'iconSrc': 'stock_tracking'
          },
          {
            'text': 'Warranty Seal',
            'routeName': 'DealerWarrantySealList',
            'iconSrc': 'warranty_seal'
          }
        ];
      default: return [];
    }
  }
};

export default {
  state,
  mutations,
  getters
};
