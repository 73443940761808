import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    'path': '/',
    'name': 'Home',
    'component': Home
  },
  {
    'path': '/about',
    'name': 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    'component': () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    'path': '/login',
    'name': 'Login',
    'component': () => import('../views/account/Landing.vue'),
    'meta': {
      'title': 'Login'
    }
  },
  {
    'path': '/dealer/login',
    'name': 'DealerLogin',
    'component': () => import('../views/account/Login.vue'),
    'meta': {
      'title': 'Admin / Dealer Login'
    }
  },
  {
    'path': '/forgot-password',
    'name': 'ForgotPassword',
    'component': () => import('../views/account/ForgotPassword.vue'),
    'meta': {
      'title': 'Forgot Password'
    }
  },
  {
    'path': '/create-password',
    'name': 'CreatePassword',
    'component': () => import('../views/account/CreatePassword.vue'),
    'meta': {
      'title': 'Create Password'
    }
  },
  {
    'path': '/reset-password',
    'name': 'ResetPassword',
    'component': () => import('../views/account/CreatePassword.vue'),
    'meta': {
      'title': 'Reset Password'
    }
  },
  {
    'path': '/admin/list',
    'name': 'AdminList',
    'component': () => import('../views/admin/List.vue'),
    'meta': {
      'title': 'Admin List'
    }
  },
  {
    'path': '/admin/dealer',
    'name': 'AdminDealerList',
    'component': () => import('../views/admin/dealer/List.vue'),
    'meta': {
      'title': 'Dealer List'
    }
  },
  {
    'path': '/admin/dealer-type',
    'name': 'AdminDealerTypeList',
    'component': () => import('../views/admin/dealer/TypeList.vue'),
    'meta': {
      'title': 'Dealer Type'
    }
  },
  {
    'path': '/admin/dealer/stock-tracking',
    'name': 'AdminDealerStockList',
    'component': () => import('../views/admin/dealer/StockTrackingList.vue'),
    'meta': {
      'title': 'Dealer Stock Tracking'
    }
  },
  {
    'path': '/admin/dealer/customers',
    'name': 'AdminDealerCustomerList',
    'component': () => import('../views/customer/List.vue'),
    'meta': {
      'title': 'Dealer Customer List'
    }
  },
  {
    'path': '/admin/dealer/warranty-seal',
    'name': 'AdminDealerWarrantySealList',
    'component': () => import('../views/admin/dealer/WarrantySeal.vue'),
    'meta': {
      'title': 'Dealer Warranty Seal'
    }
  },
  {
    'path': '/admin/customer',
    'name': 'AdminCustomerList',
    'component': () => import('../views/customer/List.vue'),
    'meta': {
      'title': 'Customer List'
    }
  },
  {
    'path': '/dealer/customers',
    'name': 'DealerCustomerList',
    'component': () => import('../views/dealer/CustomerList.vue'),
    'meta': {
      'title': 'Dealer Warranty Seal'
    }
  },
  {
    'path': '/dealer/stock-tracking',
    'name': 'DealerStockList',
    'component': () => import('../views/dealer/StockTrackingList.vue'),
    'meta': {
      'title': 'Dealer Warranty Seal'
    }
  },
  {
    'path': '/dealer/warranty-seal',
    'name': 'DealerWarrantySealList',
    'component': () => import('../views/dealer/WarrantySeal.vue'),
    'meta': {
      'title': 'Dealer Warranty Seal'
    }
  },
  {
    'path': '/customer',
    'name': 'CustomerLanding',
    'component': () => import('../views/public/index.vue'),
    'meta': {
      'title': 'Customer Login'
    }
  },
  {
    'path': '/customer/warranty',
    'name': 'CustomerWarranty',
    'component': () => import('../views/public/warranty.vue'),
    'meta': {
      'title': 'Customer Warranty'
    }
  },
  {
    'path': '/mock',
    'name': 'Verify',
    'component': () => import('../views/mock/verify.vue'),
    'meta': {
      'title': 'Mock'
    }
  }
];

const router = new VueRouter({
  routes
});

export default router;
