import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import 'bootstrap';
import '@/assets/scss/main.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'daterangepicker/daterangepicker.css';
import { Config } from './config/config';
import jsonConfig from './config/index';
import moment from 'moment';
import SearchableDropdown from '@/components/SearchableDropdown.vue';
import Pagination from '@/components/Pagination.vue';
import EventBus from '@/event-bus';

// @ts-expect-error: Ignore error explicitly.
window.$ = window.jQuery = require('jquery');
// @ts-expect-error: Ignore error explicitly.
window.daterangepicker = require('daterangepicker/daterangepicker.js');
window.moment = moment;

// with typescript
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation as any
  });
}

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    'message': messages[rule] // assign message
  });
});

extend('date_format', (value:string) => {
  const date = moment(value, 'DD/MM/YYYY', true);

  if (date.isValid()) {
    return true;
  }

  return 'The {_field_} field must be a valid date in format "DD/MM/YYYY".';
});

const config: Config = jsonConfig;

const axiosInstance = axios.create({
  'baseURL': config.apiPath
});

// eslint-disable-next-line
const vueState: any = store.state

axiosInstance.interceptors.request.use((config) => {
  if (vueState.auth.user && vueState.auth.user.token) {
    config.headers.common.Authorization = `Bearer ${vueState.auth.user.token}`;
  }
  return config;
}, (error) => (Promise.reject(error)));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        EventBus.$emit('unauthorized');
      }
    }

    return new Promise((resolve, reject) => reject(error));
  }
);

Vue.use(VueAxios, axiosInstance);

Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('SearchableDropdown', SearchableDropdown);
Vue.component('IrisPagination', Pagination);

new Vue({
  router,
  store,
  'render': h => h(App)
}).$mount('#app');
