var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"iris-pagination"},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{class:{
        'page-item': true,
        disabled: !_vm.hasPreviousPage,
      }},[_c('a',{staticClass:"page-link text-white",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToPage(1)}}},[_c('i',{staticClass:"fa fa-angle-left"})])]),_vm._l((_vm.pagesList),function(page,index){return _c('li',{key:index,class:{
        'page-item': true,
        active: _vm.value === page.pageNumber,
      }},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToPage(page.pageNumber)}}},[_vm._v(" "+_vm._s(page.display)+" ")])])}),_c('li',{class:{
        'page-item': true,
        disabled: !_vm.hasNextPage,
      }},[_c('a',{staticClass:"page-link text-white",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToPage(_vm.totalPages)}}},[_c('i',{staticClass:"fa fa-angle-right"})])])],2),_c('p',{staticClass:"mb-0 text-white text-center",staticStyle:{"font-size":"1.125rem"}},[_vm._v("Page "+_vm._s(_vm.value)+" of "+_vm._s(_vm.totalPages))])])}
var staticRenderFns = []

export { render, staticRenderFns }